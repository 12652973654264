import _ from 'lodash';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { sendEventToChannel } from './duplexer-actions';
import { getTypingUserIds } from '../selectors/typing-selectors';
import * as WS_EVENTS from '../../universal/constants/ws-events';
import { DUPLEXER_EVENTS, DUPLEXER_CHANNELS } from '../../universal/constants/duplexer';
import { CANCEL, REMOVE_ACTION } from '../../common/store/throttle-middleware/throttle-middleware';

export const ADD_TYPING_USER = 'typing/ADD_USER';
export const REMOVE_TYPING_USER = 'typing/REMOVE_USER';
export const CLEAR_TYPING_USERS = 'typing/CLEAR_USERS';
export const START_TYPING_DEBOUNCED = 'typing/START_DEBOUNCED';

export const SOCKET_TYPING_MESSAGE = WS_EVENTS.TYPING_MESSAGE;

export function emitTyping(postId, user) {
  return dispatch => {
    const eventPayload = { user, post: { _id: postId } };

    dispatch(
      sendEventToChannel({
        channelName: DUPLEXER_CHANNELS.POST,
        resourceId: postId,
        eventName: DUPLEXER_EVENTS.TYPING,
        eventPayload,
      }),
    );
  };
}

const REMOVE_TYPING_USER_TIMEOUT = 8000;

export function emitTypingThrottled(postId, user) {
  const action = emitTyping(postId, user);
  action.type = START_TYPING_DEBOUNCED;
  return action;
}

export function addTypingUser({ user }) {
  return (dispatch, getState) => {
    if (user.siteMemberId === _.get(getCurrentUser(getState()), 'siteMemberId')) {
      return;
    }
    dispatch({
      type: ADD_TYPING_USER,
      payload: user,
      meta: {
        throttle: {
          batch: 'sockets',
        },
      },
    });
  };
}

export const removeTypingUser = ({ user }) => dispatch =>
  dispatch({
    type: REMOVE_TYPING_USER,
    payload: user,
  });

const removeTypingUserDebouncedType = userId => `REMOVE_TYPING_USER_${userId}_DEBOUNCED`;

export function removeTypingUserDebounced(payload) {
  return dispatch => {
    const action = removeTypingUser(payload);
    const type = removeTypingUserDebouncedType(payload.user.siteMemberId);
    action.type = type;
    action.meta = {
      throttle: {
        batch: type,
        uniqueOnly: true,
        config: {
          debounce: REMOVE_TYPING_USER_TIMEOUT,
          uniqueOnly: true,
        },
        beforeCommit: () => {
          // remove the ADD_TYPING_USER from the bach of debounced socket actions
          dispatch({
            type: REMOVE_ACTION,
            meta: {
              throttle: {
                batch: 'sockets',
                filter: a =>
                  a.type !== ADD_TYPING_USER &&
                  a.payload.user.siteMemberId !== payload.user.siteMemberId,
              },
            },
          });
        },
      },
    };

    return dispatch(action);
  };
}

export const clearTypingUsers = () => ({ type: CLEAR_TYPING_USERS });
